export default {
  init: () => {
    const hamburgerEl = document.getElementById('hamburger')
    if (hamburgerEl) {
      const hamburgerContentEl = document.getElementById('hamburger-content')
      const body = document.querySelector('body')
      hamburgerEl.addEventListener('click', () => {
        hamburgerEl.classList.toggle('is-active')
        hamburgerContentEl.classList.toggle('open')
        body.classList.toggle('no-scroll')
      })
    }
  }
}
