const getTimestamp = moment => {
  const dateString = document
    .getElementById('coffeetime-flipdown')
    .getAttribute('data-due')
  return moment(dateString).unix()
}

export default {
  init: () => {
    if (document.getElementById('coffeetime-flipdown')) {
      Promise.all([import('flipdown'), import('moment')]).then(
        ([{ default: FlipDown }, { default: moment }]) => {
          new FlipDown(getTimestamp(moment), 'coffeetime-flipdown', {
            theme: 'light'
          }).start()
        }
      )
    }
  }
}
