class DemoForm {
  constructor(demoFormElement) {
    this.demoFormElement = demoFormElement
    this.button = new DemoFormButton(
      demoFormElement.querySelector('input[type="submit"]')
    )
    this.grecaptchaId = null

    this.onSubmit = this.onSubmit.bind(this)
    this.onReset = this.onReset.bind(this)
    this.handleRequestSuccess = this.handleRequestSuccess.bind(this)
    this.handleRequestError = this.handleRequestError.bind(this)
    this.requestSubmit = this.requestSubmit.bind(this)
  }

  registerEventListeners() {
    this.demoFormElement.addEventListener('submit', this.onSubmit)
    this.demoFormElement.addEventListener('reset', this.onReset)
    this.button.registerEventListeners()
  }

  initGrecaptcha() {
    this.grecaptchaId = window.grecaptcha.render(this.button.button, {
      sitekey: window.rlwRecaptchaSiteKey,
      callback: this.requestSubmit,
      'expired-callback': this.button.setNormal,
      'error-callback': this.button.setNormal
    })
  }

  onRecaptchaLoaded() {
    this.initGrecaptcha()

    if (this.isWaitingForRecaptcha) {
      delete this.isWaitingForRecaptcha
      window.grecaptcha.execute(this.grecaptchaId)
    }
  }

  onSubmit(e) {
    e.preventDefault()
    this.button.setSending()
    if (!window.grecaptcha) {
      this.submitBeforeRecaptcha(e)
    } else {
      this.submitAfterRecaptcha(e)
    }
  }
  submitBeforeRecaptcha() {
    if (!this.demoFormElement.checkValidity()) {
      return
    }

    this.isWaitingForRecaptcha = true
    window.loadRecaptcha()
  }
  submitAfterRecaptcha() {
    sendRequest(
      this.demoFormElement,
      this.handleRequestSuccess,
      this.handleRequestError
    )
  }

  onReset() {
    this.button.setNormal()
    if (window.grecaptcha && this.grecaptchaId) {
      window.grecaptcha.reset(this.grecaptchaId)
    }
  }

  handleRequestSuccess() {
    this.demoFormElement.reset()
    showSuccessNotification()
  }

  handleRequestError(error) {
    this.onReset()
    this.handleUnprocessableEntity(error)
    this.handleForbidden(error)
    this.demoFormElement.reportValidity()
  }
  handleUnprocessableEntity({ status, errors }) {
    if (!status || !errors || status !== 422) {
      return
    }
    Object.entries(errors).forEach(([key, errors]) => {
      const errorMsg = makeErrorMessage(errors)
      const elements = findInputElementWithKey(key, this.demoFormElement)
      elements.forEach(
        el => el.setCustomValidity && el.setCustomValidity(errorMsg)
      )
    })
  }
  handleForbidden({ status }) {
    if (!status || status !== 403) {
      return
    }
    showFailNotification()
  }

  requestSubmit() {
    this.clearCustomValidities()
    if (!this.demoFormElement.checkValidity()) {
      this.button.setNormal()
      window.grecaptcha.reset(this.grecaptchaId)
    }
    this.demoFormElement.requestSubmit()
  }
  clearCustomValidities() {
    this.demoFormElement
      .querySelectorAll('input')
      .forEach(el => el.setCustomValidity && el.setCustomValidity(''))
  }
}

class DemoFormButton {
  constructor(button) {
    this.button = button
    this.status = 'normal'

    this.setSending = this.setSending.bind(this)
    this.setNormal = this.setNormal.bind(this)
  }

  registerEventListeners() {
    // This causes a bug when dismissing recaptcha popup
    // this.button.addEventListener('click', this.setSending)
  }

  setSending() {
    if (this.checkAndSetStatus('sending')) {
      this.button.classList.add('btn--sending')
      this.savedValue = this.button.value
      this.button.value = window.rlwRecaptchaSendingValue || this.button.value
    }
  }
  setNormal() {
    if (this.checkAndSetStatus('normal')) {
      this.button.classList.remove('btn--sending')
      this.button.value = this.savedValue
      delete this.savedValue
    }
  }

  checkAndSetStatus(status) {
    if (this.status !== status) {
      this.status = status
      return true
    }
    return false
  }
}

const showSuccessNotification = () => {
  document
    .getElementById('form-success-notification')
    .show(SUCCESS_NOTIFICATION_DURATION)
}
const showFailNotification = () => {
  document
    .getElementById('form-fail-notification')
    .show(FAIL_NOTIFICATION_DURATION)
}

const findInputElementWithKey = (key, formElement) =>
  formElement.querySelectorAll(`input[name*='${key}']`)
const makeErrorMessage = errors =>
  errors.map(e => e[0].toUpperCase() + e.slice(1)).join(', ')

const sendRequest = async (form, onSuccess, onError) => {
  const formData = new FormData(form)

  try {
    await doFetch('/api/leads/demo', {
      method: 'POST',
      body: formData,
      headers: {
        Accept: 'application/json'
      }
    })
    onSuccess()
  } catch (err) {
    onError(err)
  }
}
const doFetch = async (url, obj) => {
  const response = await fetch(url, obj)
  const { body, data } = await getDataFromResponse(response)
  if (!response.ok) {
    const error = {
      status: response.status,
      statusText: response.statusText,
      errors: data && 'errors' in data ? data.errors : data,
      body
    }
    throw error
  }
  return data
}
const getDataFromResponse = async response => {
  try {
    const body = await response.text()
    const data = JSON.parse(body)
    return { body, data }
  } catch (err) {
    return {}
  }
}

const onLoadCallback = demoForms => {
  window.demoRecaptchaLoadCallback = () => {
    demoForms.forEach(demoForm => demoForm.onRecaptchaLoaded())
  }
}

export default {
  init() {
    const demoFormElements = document.querySelectorAll('form[data-demo-form]')
    const demoForms = [...demoFormElements].map(
      demoFormElement => new DemoForm(demoFormElement)
    )
    demoForms.forEach(demoForm => demoForm.registerEventListeners())
    onLoadCallback(demoForms)
  }
}
