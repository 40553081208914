export default {
  init () {
    this.initLazyImages()
    this.initBlog()
    this.initErrors()
    this.initCareer()
    this.initFactories()
    this.initEcommerce()
    this.initStartups()
    this.initCaseStudies()
  },
  initLazyImages () {
    const sections = document.querySelectorAll('.section')

    if (!sections.length) {
      return
    }

    const lazyImageObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (!entry.isIntersecting) {
          return
        }

        const lazyImages = entry.target.querySelectorAll('img[data-lazy-src], source[data-lazy-srcset]')

        if (!lazyImages.length) {
          return
        }

        lazyImages.forEach(lazyImage => {
          lazyImage.classList.remove('lazy-image-placeholder')

          if (lazyImage.dataset.lazySrc) {
            lazyImage.src = lazyImage.dataset.lazySrc
            lazyImage.removeAttribute('data-lazy-src')
          }

          if (lazyImage.dataset.lazySrcset) {
            lazyImage.srcset = lazyImage.dataset.lazySrcset
            lazyImage.removeAttribute('data-lazy-srcset')
          }
        })

        observer.unobserve(entry.target)
      })
    })

    sections.forEach(e => lazyImageObserver.observe(e))
  },
  initBlog () {
    const blogMeta = document.querySelector('meta[name="site-blog"]')
    if (blogMeta) {
      const blogContentImages = document.querySelectorAll('.blog-single-content img')
      blogContentImages.forEach(img => img.classList.add('img-safe'))

      const singlePostStylePromise = blogMeta.getAttribute('content') === 'show'
        ? import('site/stylesheets/views/_blog-single.sass')
        : Promise.resolve(true)
      return Promise.all([import('site/stylesheets/views/_blog.sass'), singlePostStylePromise])
    }
  },
  initErrors () {
    const errorMeta = document.querySelector('meta[name="site-error"]')
    if (errorMeta) {
      return import('site/stylesheets/views/_errors.sass')
    }
  },
  initCareer () {
    const careerMeta = document.querySelector('meta[name="site-career"]')
    if (careerMeta) {
      return import('site/stylesheets/views/_jobs.sass')
    }
  },
  initFactories () {
    const factoriesMeta = document.querySelector('meta[name="site-factories"]')
    if (factoriesMeta) {
      return import('site/stylesheets/views/_factories.sass')
    }
  },
  initEcommerce () {
    const ecommerceMeta = document.querySelector('meta[name="site-ecommerce"]')
    if (ecommerceMeta) {
      return import('site/stylesheets/views/_ecommerce.sass')
    }
  },
  initStartups () {
    const startupsMeta = document.querySelector('meta[name="site-startups"]')
    if (startupsMeta) {
      return import('site/stylesheets/views/_startups.sass')
    }
  },
  initCaseStudies () {
    const caseStudiesMeta = document.querySelector('meta[name="site-case-studies"]')
    if (caseStudiesMeta) {
      const caseStudy = caseStudiesMeta.getAttribute('content')

      let caseStudyStylesPromise = Promise.resolve(true)
      if (caseStudy === 'eplanning') {
        caseStudyStylesPromise = import('site/stylesheets/views/_case-study-eplanning.sass')
      }
      if (caseStudy === 'ekanban') {
        caseStudyStylesPromise = import('site/stylesheets/views/_case-study-ekanban.sass')
      }
      if (caseStudy === 'sensor_cloud') {
        caseStudyStylesPromise = import('site/stylesheets/views/_case-study-sensor_cloud.sass')
      }

      return Promise.all([import('site/stylesheets/views/_case-study-common.sass'), caseStudyStylesPromise])
    }
  }
}
