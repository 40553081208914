export default {
  init () {
    this.initGoTo()
    this.initFormSubmission()
    this.initAttachmentInput()
  },
  initGoTo: () => {
    const sectionElement = document.querySelector('[data-section-valuation]')
    const elements = document.querySelectorAll('[data-goto-valuation]')
    let url

    // Remember to update when contact routes change
    switch (document.querySelector('html').getAttribute('lang')) {
      case 'pl':
        url = '/pl/oferta?goto-valuation=true'
        break
      case 'en':
        url = '/en/offer?goto-valuation=true'
        break
      default:
        url = '#'
    }

    if (!sectionElement) {
      elements.forEach(e => {
        e.setAttribute('href', url)
      })

      return
    }

    elements.forEach(element => {
      element.addEventListener('click', (e) => {
        e.preventDefault()
        sectionElement.scrollIntoView({ behavior: 'smooth' })
      })
    })

    if (window.location.href.match(/goto-valuation/)) {
      sectionElement.scrollIntoView({ behavior: 'smooth' })
    }
  },
  initFormSubmission () {
    const formElements = document.querySelectorAll('form[data-form-valuation]')
    formElements.forEach(formElement => {
      formElement.addEventListener('reset', e => {
        const attachmentInput = formElement.elements['lead[file]']
        attachmentInput.value = ''
        attachmentInput.dispatchEvent(new Event('change'))
      })

      formElement.addEventListener('submit', e => {
        e.preventDefault()

        window.recaptchaForm = e.target
        window.executeRecaptcha()
      })
    })
  },
  initAttachmentInput () {
    const input = document.getElementById('lead[file]')
    const label = document.querySelector('label[for="lead[file]"]')

    if (!label || !input) {
      return
    }

    const originalText = label.textContent

    input.addEventListener('reset', (e) => {
      label.childNodes[0].nodeValue = originalText
    })

    input.addEventListener('change', (e) => {
      if (e.target.files.length) {
        label.childNodes[0].nodeValue = e.target.value.split('\\').pop()
      } else {
        label.childNodes[0].nodeValue = originalText
      }
    })
  }
}

const loadRecaptchaWithCallback = callback => {
  if (window.grecaptcha) {
    return false
  }

  const recaptchaScript = document.createElement('script')
  recaptchaScript.src = `https://www.google.com/recaptcha/api.js?onload=${callback}`
  document.body.appendChild(recaptchaScript)
  return true
}

window.loadRecaptcha = () => {
  loadRecaptchaWithCallback('onRecaptchaLoad')
}

window.executeRecaptcha = () => {
  if (!loadRecaptchaWithCallback('onRecaptchaLoadExecute')) {
    window.grecaptcha.execute()
  }
}

window.onRecaptchaLoad = () => {
  window.demoRecaptchaLoadCallback && window.demoRecaptchaLoadCallback()
}
window.onRecaptchaLoadExecute = () => {
  window.onRecaptchaLoad()
  window.grecaptcha.execute()
}

window.valuationRecaptchaSuccessCallback = (token) => {
  const formData = new FormData(window.recaptchaForm)
  formData.append('g-recaptcha-response', token)

  let url = ''
  if ('formValuation' in window.recaptchaForm.dataset) {
    url = '/api/leads'
  } else {
    window.grecaptcha.reset()
    return
  }

  fetch(url, {
    method: 'POST',
    body: formData,
    headers: {
      'Accept': 'application/json'
    }
  })
    .then(() => window.grecaptcha.reset())
    .then(() => window.recaptchaForm.reset())
    .then(() => document.getElementById('form-success-notification').show(SUCCESS_NOTIFICATION_DURATION))
    .catch(() => {
      window.grecaptcha.reset()
      document.getElementById('form-fail-notification').show(FAIL_NOTIFICATION_DURATION)
    })
}
