import 'site/notification'

import styles from 'site/styles'
import hamburger from 'site/hamburger'
import valuation from 'site/valuation'
import demo from 'site/demo'
import flipdown from 'site/flipdown'
import coffeetimeOnlyOnePercentGame from 'site/coffeetime/onlyOnePercentGame'
import all from '@fortawesome/fontawesome-free/js/all.js'

import 'site/stylesheets/site.sass'

import 'site/images/jedziemyrazem-screen.jpg'
import 'site/images/jedziemyrazem-screen@2x.jpg'
import 'site/images/tatralife-screen.jpg'
import 'site/images/tatralife-screen@2x.jpg'
import 'site/images/map-blank.svg'
import 'site/images/charts.svg'
import 'site/images/timeline.svg'
import 'site/images/lock-illustration.svg'

import 'site/images/carousel_slides/carousel-slide-intro-factories-bg.png'
import 'site/images/carousel_slides/carousel-slide-intro-startups-bg.png'
import 'site/images/carousel_slides/carousel-slide-oee-ui-desktop.en.png'
import 'site/images/carousel_slides/carousel-slide-oee-ui-desktop.pl.png'
import 'site/images/carousel_slides/carousel-slide-oee-ui-mobile.en.png'
import 'site/images/carousel_slides/carousel-slide-oee-ui-mobile.pl.png'
import 'site/images/carousel_slides/carousel-slide-pulse-badge.svg'
import 'site/images/carousel_slides/carousel-slide-pulse-desktop-bg.png'
import 'site/images/carousel_slides/carousel-slide-pulse-desktop-phone.en.png'
import 'site/images/carousel_slides/carousel-slide-pulse-desktop-phone.pl.png'
import 'site/images/carousel_slides/carousel-slide-pulse-mobile.en.png'
import 'site/images/carousel_slides/carousel-slide-pulse-mobile.pl.png'
import 'site/images/carousel_slides/carousel-slide-ecommerce.png'

import 'site/images/logos/logo-rubylogic.svg'
import 'site/images/logos/logo-rubylogic-alt.svg'
import 'site/images/logos/industry-tools-logo.svg'
import 'site/images/logos/rubylogic-circle.svg'
import 'site/images/logos/cooperstandard-logo.svg'
import 'site/images/logos/sabner-logo.png'

import 'site/images/products/eplaning-screen.png'
import 'site/images/products/eplaning-screen@2x.png'
import 'site/images/products/eplaning-screen2.png'
import 'site/images/products/eplaning-screen2@2x.png'
import 'site/images/products/eplaning-flat-screen.png'
import 'site/images/products/eplaning-flat-screen@2x.png'
import 'site/images/products/eplaning-flat-screen2.png'
import 'site/images/products/eplaning-flat-screen2@2x.png'
import 'site/images/products/eplaning-flat-screen3.png'
import 'site/images/products/eplaning-flat-screen3@2x.png'
import 'site/images/products/ekanban-screen.png'
import 'site/images/products/ekanban-screen@2x.png'
import 'site/images/products/ekanban-screen2.png'
import 'site/images/products/ekanban-flat-screen.png'
import 'site/images/products/ekanban-flat-screen@2x.png'
import 'site/images/products/ekanban-flat-screen2.png'
import 'site/images/products/ekanban-flat-screen2@2x.png'
import 'site/images/products/ekanban-flat-screen3.png'
import 'site/images/products/ekanban-flat-screen3@2x.png'
import 'site/images/products/ekanban-screen2@2x.png'
import 'site/images/products/actionplan-screen.png'
import 'site/images/products/actionplan-screen@2x.png'
import 'site/images/products/actionplan-screen2.png'
import 'site/images/products/actionplan-screen2@2x.png'
import 'site/images/products/actionplan-screen3.png'
import 'site/images/products/actionplan-screen3@2x.png'
import 'site/images/products/actionplan-flat-screen.png'
import 'site/images/products/actionplan-flat-screen@2x.png'
import 'site/images/products/actionplan-flat-screen2.png'
import 'site/images/products/actionplan-flat-screen2@2x.png'
import 'site/images/products/actionplan-flat-screen3.png'
import 'site/images/products/actionplan-flat-screen3@2x.png'
import 'site/images/products/actionplan-flat-screen4.png'
import 'site/images/products/actionplan-flat-screen4@2x.png'
import 'site/images/products/actionplan-flat-screen5.png'
import 'site/images/products/actionplan-flat-screen5@2x.png'
import 'site/images/products/actionplan-flat-screen6.png'
import 'site/images/products/actionplan-flat-screen6@2x.png'
import 'site/images/products/avisboard-screen.png'
import 'site/images/products/avisboard-screen@2x.png'
import 'site/images/products/avisboard-flat-screen.png'
import 'site/images/products/avisboard-flat-screen@2x.png'
import 'site/images/products/avisboard-flat-screen2.png'
import 'site/images/products/avisboard-flat-screen2@2x.png'
import 'site/images/products/avisboard-flat-screen3.png'
import 'site/images/products/avisboard-flat-screen3@2x.png'
import 'site/images/products/omms-screen.png'
import 'site/images/products/omms-screen@2x.png'
import 'site/images/products/omms-screen2.png'
import 'site/images/products/omms-screen2@2x.png'
import 'site/images/products/mttr_calc.png'
import 'site/images/products/mttr_calc@2x.png'
import 'site/images/products/bip-screen.png'
import 'site/images/products/bip-screen@2x.png'
import 'site/images/products/saas_actionplan.png'
import 'site/images/products/saas_actionplan@2x.png'
import 'site/images/products/saas_actionplan-screen1.png'
import 'site/images/products/saas_actionplan-screen1@2x.png'
import 'site/images/products/saas_actionplan-screen2.png'
import 'site/images/products/saas_actionplan-screen2@2x.png'
import 'site/images/products/saas_actionplan-screen3.png'
import 'site/images/products/saas_actionplan-screen3@2x.png'
import 'site/images/products/saas_ceodash.png'
import 'site/images/products/saas_ceodash@2x.png'
import 'site/images/products/saas_ceodash-screen1.png'
import 'site/images/products/saas_ceodash-screen1@2x.png'
import 'site/images/products/saas_ceodash-screen2.png'
import 'site/images/products/saas_ceodash-screen2@2x.png'
import 'site/images/products/saas_ceodash-screen3.png'
import 'site/images/products/saas_ceodash-screen3@2x.png'
import 'site/images/products/saas_oeetools.png'
import 'site/images/products/saas_oeetools@2x.png'
import 'site/images/products/saas_oeetools-screen1.png'
import 'site/images/products/saas_oeetools-screen1@2x.png'
import 'site/images/products/saas_oeetools-screen2.png'
import 'site/images/products/saas_oeetools-screen2@2x.png'
import 'site/images/products/saas_oeetools-screen3.png'
import 'site/images/products/saas_oeetools-screen3@2x.png'

import 'site/images/startups/development-figure.svg'
import 'site/images/startups/tatralife.png'
import 'site/images/startups/tatralife-tablet.png'
import 'site/images/startups/tatralife-desktop.png'
import 'site/images/startups/jedziemyrazem.png'
import 'site/images/startups/jedziemyrazem-tablet.png'
import 'site/images/startups/jedziemyrazem-desktop.png'
import 'site/images/startups/skilldepository.png'
import 'site/images/startups/skilldepository-tablet.png'
import 'site/images/startups/skilldepository-desktop.png'

import 'site/images/banners/banner-contact.png'
import 'site/images/banners/banner-contact@2x.png'
import 'site/images/banners/banner-contact-mobile.png'
import 'site/images/banners/banner-contact-tablet.png'
import 'site/images/banners/banner-solutions.svg'
import 'site/images/banners/banner-solutions-tablet.svg'
import 'site/images/banners/banner-solutions-mobile.svg'
import 'site/images/banners/banner-brief.svg'
import 'site/images/banners/banner-brief-tablet.svg'
import 'site/images/banners/banner-brief-mobile.svg'
import 'site/images/banners/banner-development.svg'
import 'site/images/banners/banner-development-tablet.svg'
import 'site/images/banners/banner-development-mobile.svg'
import 'site/images/banners/banner-cs-eplanning-production.svg'
import 'site/images/banners/banner-cs-eplanning-production-tablet.svg'
import 'site/images/banners/banner-cs-eplanning-production-mobile.svg'

import 'site/images/ecommerce/ecommerce-start-selling-flower.png'
import 'site/images/ecommerce/ecommerce-start-selling-flower@1x.png'
import 'site/images/ecommerce/ecommerce-start-selling-flower@2x.png'
import 'site/images/ecommerce/ecommerce-start-selling-headphones.png'
import 'site/images/ecommerce/ecommerce-start-selling-headphones@1x.png'
import 'site/images/ecommerce/ecommerce-start-selling-headphones@2x.png'
import 'site/images/ecommerce/ecommerce-start-selling-headphones-mobile.png'
import 'site/images/ecommerce/ecommerce-start-selling-headphones-mobile@1x.png'
import 'site/images/ecommerce/ecommerce-start-selling-headphones-mobile@2x.png'
import 'site/images/ecommerce/ecommerce-start-selling-armchair.png'
import 'site/images/ecommerce/ecommerce-start-selling-armchair@1x.png'
import 'site/images/ecommerce/ecommerce-start-selling-armchair@2x.png'
import 'site/images/ecommerce/ecommerce-banner-2.png'
import 'site/images/ecommerce/ecommerce-banner-2@1x.png'
import 'site/images/ecommerce/ecommerce-banner-2@2x.png'
import 'site/images/ecommerce/ecommerce-banner-3.png'
import 'site/images/ecommerce/ecommerce-banner-3@1x.png'
import 'site/images/ecommerce/ecommerce-banner-3@2x.png'
import 'site/images/ecommerce/ecommerce-custom-solutions.svg'
import 'site/images/ecommerce/ecommerce-tested-solutions.pl.png'
import 'site/images/ecommerce/ecommerce-tested-solutions.pl@1x.png'
import 'site/images/ecommerce/ecommerce-tested-solutions.pl@2x.png'
import 'site/images/ecommerce/ecommerce-tested-solutions.en.png'
import 'site/images/ecommerce/ecommerce-tested-solutions.en@1x.png'
import 'site/images/ecommerce/ecommerce-tested-solutions.en@2x.png'
import 'site/images/ecommerce/ecommerce-tested-solutions.de.png'
import 'site/images/ecommerce/ecommerce-tested-solutions.de@1x.png'
import 'site/images/ecommerce/ecommerce-tested-solutions.de@2x.png'
import 'site/images/ecommerce/ecommerce-detailed-statistics.png'
import 'site/images/ecommerce/ecommerce-detailed-statistics@1x.png'
import 'site/images/ecommerce/ecommerce-detailed-statistics@2x.png'
import 'site/images/ecommerce/ecommerce-solutions-for-any-device.pl.png'
import 'site/images/ecommerce/ecommerce-solutions-for-any-device.pl@1x.png'
import 'site/images/ecommerce/ecommerce-solutions-for-any-device.pl@2x.png'
import 'site/images/ecommerce/ecommerce-solutions-for-any-device.en.png'
import 'site/images/ecommerce/ecommerce-solutions-for-any-device.en@1x.png'
import 'site/images/ecommerce/ecommerce-solutions-for-any-device.en@2x.png'
import 'site/images/ecommerce/ecommerce-solutions-for-any-device.de.png'
import 'site/images/ecommerce/ecommerce-solutions-for-any-device.de@1x.png'
import 'site/images/ecommerce/ecommerce-solutions-for-any-device.de@2x.png'

import 'site/images/factories/success-story.jpg'

import 'site/images/offers/figure.svg'

import 'site/images/technologies/ruby-icon.svg'
import 'site/images/technologies/rails-icon.svg'
import 'site/images/technologies/elixir-icon.svg'
import 'site/images/technologies/slack-icon.svg'
import 'site/images/technologies/react-icon.svg'
import 'site/images/technologies/swift-icon.svg'
import 'site/images/technologies/js-icon.svg'
import 'site/images/technologies/phoenix-icon.svg'
import 'site/images/technologies/spree-icon.svg'
import 'site/images/technologies/capistrano-icon.svg'
import 'site/images/technologies/docker-icon.svg'

import 'site/images/icons/analytics-icon.svg'
import 'site/images/icons/arrow-icon.svg'
import 'site/images/icons/box-icon.svg'
import 'site/images/icons/bulb-icon.svg'
import 'site/images/icons/code-icon.svg'
import 'site/images/icons/control-icon.svg'
import 'site/images/icons/db-icon.svg'
import 'site/images/icons/database-design-icon.svg'
import 'site/images/icons/design-icon.svg'
import 'site/images/icons/factory-icon.svg'
import 'site/images/icons/gear-icon.svg'
import 'site/images/icons/gold-arrow-icon.svg'
import 'site/images/icons/integration-icon.svg'
import 'site/images/icons/iot-icon.svg'
import 'site/images/icons/livedata-icon.svg'
import 'site/images/icons/livedata-yellow-icon.svg'
import 'site/images/icons/logistics-icon.svg'
import 'site/images/icons/managing-icon.svg'
import 'site/images/icons/mobile-app-icon.svg'
import 'site/images/icons/pencil-icon.svg'
import 'site/images/icons/quality-icon.svg'
import 'site/images/icons/quality-yellow-icon.svg'
import 'site/images/icons/robot-icon.svg'
import 'site/images/icons/ruby-icon.svg'
import 'site/images/icons/shoot-arrow-icon.svg'
import 'site/images/icons/web-design-icon.svg'

import 'site/images/home_case_studies/home-case-study-ap.png'
import 'site/images/home_case_studies/home-case-study-ap@2x.png'
import 'site/images/home_case_studies/home-case-study-ekanban-tablet.png'
import 'site/images/home_case_studies/home-case-study-ekanban-tablet@2x.png'
import 'site/images/home_case_studies/home-case-study-ekanban.png'
import 'site/images/home_case_studies/home-case-study-ekanban@2x.png'
import 'site/images/home_case_studies/home-case-study-epc.png'
import 'site/images/home_case_studies/home-case-study-epc@2x.png'
import 'site/images/home_case_studies/home-case-study-sensorcloud.png'
import 'site/images/home_case_studies/home-case-study-sensorcloud@2x.png'

import 'site/images/case_studies/eplanning/solution1_1.png'
import 'site/images/case_studies/eplanning/solution1_1@2x.png'
import 'site/images/case_studies/eplanning/solution1_2.png'
import 'site/images/case_studies/eplanning/solution1_2@2x.png'
import 'site/images/case_studies/eplanning/solution2_1.png'
import 'site/images/case_studies/eplanning/solution2_1@2x.png'
import 'site/images/case_studies/eplanning/solution3_1.png'
import 'site/images/case_studies/eplanning/solution3_1@2x.png'
import 'site/images/case_studies/eplanning/solution3_2.png'
import 'site/images/case_studies/eplanning/solution3_2@2x.png'
import 'site/images/case_studies/eplanning/analysis_1.png'
import 'site/images/case_studies/eplanning/analysis_1@2x.png'
import 'site/images/case_studies/eplanning/analysis_2.png'
import 'site/images/case_studies/eplanning/analysis_2@2x.png'
import 'site/images/case_studies/eplanning/analysis_3.png'
import 'site/images/case_studies/eplanning/analysis_3@2x.png'
import 'site/images/case_studies/eplanning/oee_1.png'
import 'site/images/case_studies/eplanning/oee_1@2x.png'
import 'site/images/case_studies/eplanning/oee_2.png'
import 'site/images/case_studies/eplanning/oee_2@2x.png'
import 'site/images/case_studies/eplanning/oee_3.png'
import 'site/images/case_studies/eplanning/oee_3@2x.png'
import 'site/images/case_studies/eplanning/ekanban.png'
import 'site/images/case_studies/eplanning/ekanban@2x.png'
import 'site/images/case_studies/eplanning/planning1.png'
import 'site/images/case_studies/eplanning/planning1@2x.png'
import 'site/images/case_studies/eplanning/planning2.png'
import 'site/images/case_studies/eplanning/planning2@2x.png'
import 'site/images/case_studies/eplanning/screen-macbook-casestudy.png'
import 'site/images/case_studies/eplanning/screen-macbook-casestudy@2x.png'

import 'site/images/case_studies/ekanban/solution1_1.png'
import 'site/images/case_studies/ekanban/solution1_1@2x.png'
import 'site/images/case_studies/ekanban/solution1_2.png'
import 'site/images/case_studies/ekanban/solution1_2@2x.png'
import 'site/images/case_studies/ekanban/solution2_1.png'
import 'site/images/case_studies/ekanban/solution2_1@2x.png'
import 'site/images/case_studies/ekanban/solution3_1.png'
import 'site/images/case_studies/ekanban/solution3_1@2x.png'
import 'site/images/case_studies/ekanban/solution3_2.png'
import 'site/images/case_studies/ekanban/solution3_2@2x.png'
import 'site/images/case_studies/ekanban/analysis.png'
import 'site/images/case_studies/ekanban/analysis@2x.png'
import 'site/images/case_studies/ekanban/operator.png'
import 'site/images/case_studies/ekanban/operator@2x.png'
import 'site/images/case_studies/ekanban/trolley.png'
import 'site/images/case_studies/ekanban/trolley@2x.png'
import 'site/images/case_studies/ekanban/trolley_animation.png'
import 'site/images/case_studies/ekanban/trolley_animation@2x.png'
import 'site/images/case_studies/ekanban/diagram.svg'
import 'site/images/case_studies/ekanban/screen-macbook-casestudy.png'
import 'site/images/case_studies/ekanban/screen-macbook-casestudy@2x.png'
import 'site/images/case_studies/ekanban/banner.svg'
import 'site/images/case_studies/ekanban/banner-tablet.svg'
import 'site/images/case_studies/ekanban/banner-mobile.svg'

import 'site/images/case_studies/sensor_cloud/header.png'
import 'site/images/case_studies/sensor_cloud/header@2x.png'
import 'site/images/case_studies/sensor_cloud/architecture.svg'
import 'site/images/case_studies/sensor_cloud/dashboard1.png'
import 'site/images/case_studies/sensor_cloud/dashboard1@2x.png'
import 'site/images/case_studies/sensor_cloud/dashboard2.png'
import 'site/images/case_studies/sensor_cloud/dashboard2@2x.png'
import 'site/images/case_studies/sensor_cloud/alarms1.png'
import 'site/images/case_studies/sensor_cloud/alarms1@2x.png'
import 'site/images/case_studies/sensor_cloud/alarms2.png'
import 'site/images/case_studies/sensor_cloud/alarms2@2x.png'
import 'site/images/case_studies/sensor_cloud/sms-mail-alert.svg'
import 'site/images/case_studies/sensor_cloud/report.png'
import 'site/images/case_studies/sensor_cloud/report@2x.png'
import 'site/images/case_studies/sensor_cloud/banner.svg'
import 'site/images/case_studies/sensor_cloud/banner-tablet.svg'
import 'site/images/case_studies/sensor_cloud/banner-mobile.svg'

import 'site/images/contact/contact_page_text.svg'

import 'site/images/coffeetime/coffeetime-header.svg'
import 'site/images/coffeetime/coffeetime-coffee.png'
import 'site/images/coffeetime/coffeetime-coffee@2x.png'

import 'site/images/team/alek.png'
import 'site/images/team/alek@2x.png'

import Splide from '@splidejs/splide'

import Turbolinks from 'turbolinks'

Turbolinks.start()

document.addEventListener('turbolinks:load', () => {
  styles.init()
  hamburger.init()
  valuation.init()
  demo.init()
  flipdown.init()
  coffeetimeOnlyOnePercentGame.init()

  const animatedAchievementsMapContainer = document.getElementById('animated-achievements-map')
  const animatedAchievementsMapSVG = document.querySelector('#animated-achievements-map > svg')
  if (animatedAchievementsMapContainer && !animatedAchievementsMapSVG) {
    Promise.all([import('site/animations/achievements-map.json'), import('lottie-web')]).then(([achievementsMapAnimation, lottie]) => {
      lottie.loadAnimation({
        container: animatedAchievementsMapContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: achievementsMapAnimation
      })
    })
  }

  const animatedFactoryContainer = document.getElementById('animated-factory')
  const animatedFactorySVG = document.querySelector('#animated-factory > svg')
  if (animatedFactoryContainer && !animatedFactorySVG) {
    Promise.all([import('site/animations/factory.json'), import('lottie-web')]).then(([factoryAnimation, lottie]) => {
      lottie.loadAnimation({
        container: animatedFactoryContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: factoryAnimation
      })
    })
  }

  const animatedClimbersContainer = document.getElementById('animated-climbers')
  const animatedClimbersSVG = document.querySelector('#animated-climbers > svg')
  if (animatedClimbersContainer && !animatedClimbersSVG) {
    Promise.all([import('site/animations/climbers.json'), import('lottie-web')]).then(([climbersAnimation, lottie]) => {
      lottie.loadAnimation({
        container: animatedClimbersContainer,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: climbersAnimation
      })
    })
  }

  const animated500Container = document.getElementById('animated-500')
  const animated500SVG = document.querySelector('#animated-500 > svg')
  if (animated500Container && !animated500SVG) {
    Promise.all([import('site/animations/500.json'), import('lottie-web')]).then(([animation, lottie]) => {
      lottie.loadAnimation({
        container: animated500Container,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        animationData: animation
      })
    })
  }

  const splide = document.querySelectorAll('.splide').forEach(el => {
    Promise.all([import('@splidejs/splide/dist/css/splide.min.css')]).then(() => {
      new Splide(el, { type: 'loop', autoplay: true }).mount()
    })
  })

  const equations = document.querySelectorAll('equation')
  if (equations.length) {
    Promise.all([import('katex'), import('katex/dist/katex.min.css')]).then(([katex]) => {
      equations.forEach(node => {
        if (!node.querySelector('script')) {
          katex.render(node.textContent, node)
        }
      })
    })
  }

  const ta = 'acarp'
  const tb = 'cigolybur'
  const te = ta.split('').reverse().join('') + '@' + tb.split('').reverse().join('') + '.pl'
  document.querySelectorAll('.n3ma11-parser').forEach(el => {
    el.innerHTML = el.innerHTML.replace(/\[\[3ma11\]\]/g, te)
  })

  if (window.location.search.match(/no_job/)) {
    const noJobNotification = document.getElementById('no-job-notification')
    if (noJobNotification) {
      noJobNotification.show(FAIL_NOTIFICATION_DURATION)
    }
  }

  window.grecaptcha = null
})
