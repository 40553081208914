(function () {
  if (!window.customElements) {
    return
  }

  class Notification extends window.HTMLElement {
    connectedCallback () {
      const shadowRoot = this.attachShadow({ mode: 'open' })
      shadowRoot.innerHTML = `
        <style>
          #container {
            position: fixed;
            z-index: 101;
            top: 0;
            left: 0;
            right: 0;
            text-align: center;
            overflow: hidden;
            display: none;
            justify-content: center;
            align-items: center;
            height: 80px;
            color: #FFFFFF;
            font-family: Montserrat;
          }

          #container[opened] {
            display: flex;
          }

          #container[type="info"] {
            background-color: rgb(83, 172, 52);
          }

          #container[type="info"] .icon:after {
            content: '\\f003';
          }

          #container[type="alert"] {
            background-color: rgb(232, 83, 83);
          }

          #container[type="alert"] .icon:after {
            content: '\\f071';
          }

          .icon:after {
            font-family: FontAwesome;
            margin-right: 10px;
            display: block;
          }
        </style>

        <div id="container">
          <span class="icon"></span><span>${this.getAttribute('message')}</span>
        </div>
      `

      const containerElement = this.shadowRoot.getElementById('container')
      containerElement.setAttribute('type', this.getAttribute('type'))
    }

    attributeChangedCallback (name, oldValue, newValue) {
      if (!this.shadowRoot) {
        return
      }

      const containerElement = this.shadowRoot.getElementById('container')

      if (newValue === null) {
        containerElement.removeAttribute(name)
      } else {
        containerElement.setAttribute(name, newValue)
      }
    }

    static get observedAttributes () {
      return ['opened', 'type']
    }

    show (closeAfter = 3000) {
      this.setAttribute('opened', '')

      if (closeAfter) {
        setTimeout(() => {
          this.removeAttribute('opened')
        }, closeAfter)
      }
    }
  }

  window.SUCCESS_NOTIFICATION_DURATION = 5000
  window.FAIL_NOTIFICATION_DURATION = 15000
  window.customElements.define('site-notification', Notification)
})()
