function coffeetimeOnlyOnePercentGameInit (inputElement) {
  const DEBOUNCE_TIME = 600
  const MESSAGE_SELECTOR = '[data-coffeetime-message]'
  const LOSE_ATTR = 'coffeetimeLose'
  const WIN_ATTR = 'coffeetimeWin'
  const INPUT_DATA_ATTRIBUTE = 'data-coffeetime-game'

  const messageElement = document.querySelector(MESSAGE_SELECTOR)
  let answer = null
  let debounce = null

  const handleDebounceInput = () => {
    clearDebounce()
    clearLoseWhenTyping()
    debounce = setTimeout(() => {
      handleInput()
      clearDebounce()
    }, DEBOUNCE_TIME)
  }
  const clearDebounce = () => {
    if (debounce !== null) {
      clearTimeout(debounce)
      debounce = null
    }
  }
  const clearLoseWhenTyping = () => {
    const dataset = messageElement.dataset
    if (!(WIN_ATTR in dataset) && LOSE_ATTR in dataset) {
      delete dataset[LOSE_ATTR]
    }
  }

  const handleInput = () => {
    const input = inputElement.value.trim()
    if (input === '') {
      clearLoseWhenTyping()
    } else if (input === answer) {
      handleWin()
    } else {
      handleLose()
    }
  }
  const handleWin = () => {
    const dataset = messageElement.dataset
    if (!(WIN_ATTR in dataset)) {
      dataset[WIN_ATTR] = ''
      inputElement.dataset[WIN_ATTR] = ''
    }
  }
  const handleLose = () => {
    const dataset = messageElement.dataset
    if (!(WIN_ATTR in dataset)) {
      dataset[LOSE_ATTR] = ''
    }
  }

  const getAnswerFromTagAttribute = () => {
    if (!inputElement.hasAttribute(INPUT_DATA_ATTRIBUTE)) {
      return
    }
    const encodedData = inputElement.getAttribute(INPUT_DATA_ATTRIBUTE)
    inputElement.removeAttribute(INPUT_DATA_ATTRIBUTE)
    if (window.atob) {
      answer = window.atob(encodedData)
    }
  }

  getAnswerFromTagAttribute()
  inputElement.addEventListener('input', handleDebounceInput)
}

export default {
  init: () => {
    const element = document.querySelector('[data-coffeetime-input]')
    if (!element) {
      return
    }

    coffeetimeOnlyOnePercentGameInit(element)
  }
}
